<template>
  <div class="authentication-container">
    <div class="authentication-box">
      <div class="loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="tip">正在登录，请稍后...</div>
    </div>
  </div>
</template>
<script>
import { getUrlParams, showModel } from "@/utils/tools";
import * as storage from "@/utils/storage";
import { getOauth } from "@/api/userController";

export default {
  name: "Authentication",
  created() {
    // 验证是否登录
    storage.clearStore("webToken")
    this.validate();
  },
  methods: {
    validate() {
      const query = getUrlParams(location.href);
      if (query.hash.usertoken || query.search.usertoken) {
        getOauth({
          usertoken: query.hash.usertoken || query.search.usertoken,
        }).then((res) => {
          storage.setStore("webToken", res.data.token);
          storage.setStore(
            "userToken",
            query.hash.usertoken || query.search.usertoken
          );
          storage.setStore("webExpire", res.data.expire);
          storage.setStore("webUserInfo", {
            username: res.data.username,
            tel: res.data.tel,
          });
          if (
            res.data.isCurrentYearEnum !== "" &&
            res.data.isCurrentYearEnum !== null &&
            res.data.isCurrentYearEnum !== undefined
          ) {
            storage.setStore("isCurrentYearEnum", res.data.isCurrentYearEnum);
          }
          if (
            res.data.singType !== "" &&
            res.data.singType !== null &&
            res.data.singType !== undefined
          ) {
            storage.setStore("singType", res.data.singType);
          }
          if (
            res.data.year !== "" &&
            res.data.year !== null &&
            res.data.year !== undefined
          ) {
            storage.setStore("year", res.data.year);
          }
          if (
            res.data.yearId !== "" &&
            res.data.yearId !== null &&
            res.data.yearId !== undefined
          ) {
            storage.setStore("yearId", res.data.yearId);
          }
          if (res.data.jumpType === 1) {
            // 直接跳转学习页面
            this.keepAliveNavigation("/specialized");
          } else if (res.data.jumpType === 2) {
            // 直接跳转报名页面
            this.keepAliveNavigation("/signUp");
          }
          if (query.hash.redirect || query.search.redirect) {
            window.location.href.replace(
              query.hash.redirect || query.search.redirect
            );
          }
        });
      } else {
        showModel({
          content: "登录失效！请重新登录",
          title: "温馨提示",
          showClose: false,
          confirm: () => {
            // 返回登录页面
            storage.clearAll();
            window.location.replace("https://www.sclpa.cn/Default.aspx#");
          },
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.authentication-container {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 200px;
  .authentication-box {
    display: inline-block;
  }
  .tip {
    font-size: 18px;
    font-weight: bold;
    color: #c94f4f;
  }
}
.loading {
  margin: 20px auto;
  position: relative;
  box-sizing: border-box;
}
.loading > div {
  position: relative;
  box-sizing: border-box;
}

.loading {
  display: block;
  font-size: 0;
  color: #686868;
}

.loading.la-dark {
  color: #686868;
}

.loading > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}

.loading {
  width: 82px;
  height: 72px;
}

.loading > div:nth-child(1) {
  position: absolute;
  bottom: 32%;
  left: 18%;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  transform-origin: center bottom;
  animation: ball-climbing-dot-jump 0.6s ease-in-out infinite;
}

.loading > div:not(:nth-child(1)) {
  position: absolute;
  top: 0;
  right: 0;
  width: 34px;
  height: 2px;
  border-radius: 0;
  transform: translate(60%, 0);
  animation: ball-climbing-dot-steps 1.8s linear infinite;
}

.loading > div:not(:nth-child(1)):nth-child(2) {
  animation-delay: 0ms;
}

.loading > div:not(:nth-child(1)):nth-child(3) {
  animation-delay: -600ms;
}

.loading > div:not(:nth-child(1)):nth-child(4) {
  animation-delay: -1200ms;
}

@keyframes ball-climbing-dot-jump {
  0% {
    transform: scale(1, 0.7);
  }

  20% {
    transform: scale(0.7, 1.2);
  }

  40% {
    transform: scale(1, 1);
  }

  50% {
    bottom: 125%;
  }

  46% {
    transform: scale(1, 1);
  }

  80% {
    transform: scale(0.7, 1.2);
  }

  90% {
    transform: scale(0.7, 1.2);
  }

  100% {
    transform: scale(1, 0.7);
  }
}

@keyframes ball-climbing-dot-steps {
  0% {
    top: 0;
    right: 0;
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    top: 100%;
    right: 100%;
    opacity: 0;
  }
}
</style>
